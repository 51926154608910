import { useEffect, useState } from 'react'

import { useHistoryTrap } from '../hooks/functional/useHistoryTrap'

type UserAgentHistoryTrapProps = {
    userAgent: string | string[]
    trapUrl?: string
    children: never
    eventQuery?: string
}

const useUserAgent = () => {
    const [userAgent, setUserAgent] = useState<string>('')

    useEffect(() => {
        setUserAgent(navigator.userAgent)
    }, [])

    return { userAgent }
}

export const UserAgentHistoryTrap = ({
    userAgent: userAgentInput,
    trapUrl = '/',
    eventQuery = 'utm_source=fb-app&utm_medium=history-trap'
}: UserAgentHistoryTrapProps) => {
    const { userAgent: currentUserAgent } = useUserAgent()
    const userAgent = (Array.isArray(userAgentInput) ? userAgentInput : [userAgentInput]).flat()

    const fullUrl = `${trapUrl}${trapUrl.includes('?') ? '&' : '?'}${eventQuery}`
    useHistoryTrap({ enabled: userAgent.some(ua => currentUserAgent.includes(ua)), trapUrl: fullUrl })
}

export const UserAgent = Object.freeze({
    FACEBOOK: ['FBAN', 'FBAV']
})
