import { useEffect, useState } from 'react'

import { useHistoryTrap } from '../hooks/functional/useHistoryTrap'

type ReferrerHistoryTrapProps = {
    referrer: string | string[]
    trapUrl?: string
    eventQuery?: string
}

const useIsReferrer = (refererToDetect: string | string[]) => {
    const [isReferrer, setIsReferrer] = useState<boolean>(false)
    const [referrer, setReferrer] = useState<string>('')

    useEffect(() => {
        const referrerArray = Array.isArray(refererToDetect) ? refererToDetect : [refererToDetect]
        setIsReferrer(referrerArray.some(r => document.referrer.includes(r)))
        setReferrer(document.referrer)
    }, [refererToDetect])

    return { isReferrer, referrer }
}

export const ReferrerHistoryTrap = ({
    referrer: referrerInput,
    trapUrl = '/',
    eventQuery = 'utm_source=google-discover&utm_medium=history-trap'
}: ReferrerHistoryTrapProps) => {
    const { isReferrer: isReferrer } = useIsReferrer(referrerInput)
    const fullUrl = `${trapUrl}${trapUrl.includes('?') ? '&' : '?'}${eventQuery}`
    useHistoryTrap({ enabled: isReferrer, trapUrl: fullUrl })
}

export const Referrer = Object.freeze({
    GOOGLE: [
        // 'www.google.com', // General Google
        'news.google.com', // Google News
        'com.google.android.googlequicksearchbox' // Google Discover
    ]
})
