/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useRef } from 'react'

declare global {
    interface Window {
        __reroutedRef?: boolean
    }
}

type UseHistoryTrapProps = {
    enabled: boolean
    trapUrl?: string
}
export const useHistoryTrap = ({ enabled = false, trapUrl }: UseHistoryTrapProps) => {
    const trappedRef = useRef(false)
    useEffect(() => {
        if (!window || !enabled || trappedRef.current || !trapUrl) {
            return
        }

        const targetUrl = window.location.pathname
        if (targetUrl.split('?')[0] === trapUrl.split('?')[0]) {
            return
        }
        // Set the trapped flag to prevent multiple traps
        trappedRef.current = true
        const initialState = { type: 'zero-bounce', url: trapUrl }

        window.history.replaceState(initialState, '', trapUrl)
        window.history.pushState(initialState, '', targetUrl)

        // Handle back navigation
        window.onpopstate = (event: PopStateEvent) => {
            if (window.__reroutedRef) {
                return
            }
            window.__reroutedRef = true

            event.preventDefault()

            const { state } = event
            if (state && state.type === 'zero-bounce') {
                // Redirect to the homepage if the back button is pressed
                window.location.assign(trapUrl)
            }
        }
        return () => {
            window.onpopstate = null
        }
    }, [trapUrl, enabled])
}
