import 'regenerator-runtime/runtime'

import { Provider as ReduxStoreProvider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import { useAmp } from 'next/amp'
import getConfig from 'next/config'
import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'
import PropTypes from 'prop-types'

import ExternalAdsProvider from '@hmn/rtl-web-core/components/Ads/External/ExternalAds.provider'
import { TrackingScripts } from '@hmn/rtl-web-core/components/analytics/TrackingScripts'
import { AdCallbacksContext } from '@hmn/rtl-web-core/context/ads/callback.context'
import { FilledSlotsContext } from '@hmn/rtl-web-core/context/ads/filled.context'
import { AdManifestContext } from '@hmn/rtl-web-core/context/ads/manifest.context'
import { RefreshSlotsContext } from '@hmn/rtl-web-core/context/ads/refresh'
import { ClientNavigationContextProvider } from '@hmn/rtl-web-core/context/clientNavigation'
import { NavigationContext } from '@hmn/rtl-web-core/context/navigation'
import {
    LunaContext,
    SettingsProvider,
    UpScoreContextProvider,
    UserContextProvider
} from '@hmn/rtl-web-core/controllers'
import AdsProvider from '@hmn/rtl-web-core/controllers/AdsProvider/Ads.provider'
import { combineWrappers } from '@hmn/rtl-web-core/controllers/combineWrappers'
import { DidomiProvider } from '@hmn/rtl-web-core/controllers/DidomiProvider'
import { Referrer, ReferrerHistoryTrap } from '@hmn/rtl-web-core/controllers/ReferrerHistoryTrap'
import { UserAgent, UserAgentHistoryTrap } from '@hmn/rtl-web-core/controllers/UserAgentHistoryTrap'
import pageVariants from '@hmn/rtl-web-core/helpers/pageVariants'
import { getBaseApiURL } from '@hmn/rtl-web-core/hooks'
import { IsolatedAppHooks } from '@hmn/rtl-web-core/hooks/useAppHooks'
import { QueryCacheProvider } from '@hmn/rtl-web-core/queries'
import { store } from '@hmn/rtl-web-core/store/store'
import { AppName } from '@hmn/rtl-web-core/types/app'

import { GlobalStyles } from '@hmn/rtl-zena-ui/components/GlobalStyles'
import { DefaultSeo } from '@hmn/rtl-zena-ui/components/Seo'
import { themes } from '@hmn/rtl-zena-ui/theme'

import dataProvider, { AwsS3StorageService } from '@hmn/data-provider'

import 'swiper/swiper-bundle.css'
import '../styles/fonts.css'
import '../styles/fallbackFonts.css'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-ads/dist/videojs.ads.css'
import 'videojs-ima/dist/videojs.ima.css'
import '../styles/rtl-video-js.css'
import 'survey-core/defaultV2.min.css'
import { ProfileLayout } from '../layouts'
import SEO from '../next-seo.config'

const { publicRuntimeConfig = {} } = getConfig()
const { xClientName } = publicRuntimeConfig

// Override default theme here, this can be exposed through state or API
const currentTheme = 'zenaDefault'

AwsS3StorageService.init({ fetch })

dataProvider.init({
    baseUrl: getBaseApiURL({ api: 'human', externalOnly: true }),
    storageService: AwsS3StorageService,
    token: process.env.NEXT_PUBLIC_HUMAN_API_READONLY_TOKEN_ZENA,
    language: 'hr'
})

const themesObject = { themes }

const FB_PIXEL_ID = '864000226967467'
const GEMIUS_IDENTIFIER = process.env.NEXT_PUBLIC_GEMIUS_ID_ZENA
const EXPONEA_TOKEN = '590f3dcc-2414-11ef-a8fa-9ab3afd0692b'

const didomiSettings = {
    apiKey: 'c15af460-4782-467a-8d5b-88619667de76',
    noticeId: 'gFr8YJJd',
    iabVersion: 2,
    gdprAppliesGlobally: true
}

const dependantZonesMap = {
    'M - Hardcoded Anchor': ['M - Floating'],
    'D - Hardcoded Anchor': ['D - Wallpaper - left'],
    'D - Hardcoded Wallpaper - right': ['D - Wallpaper - right'],
    'D - Hardcoded Wallpaper - left': ['D - Wallpaper - right']
}

const ignoreZonesOnShallowNavigation = {
    article: {
        'D - Hardcoded Anchor': true,
        'M - Hardcoded Anchor': true,
        'D - Billboard - top': true,
        'M - Billboard - top': true
    },
    column: {
        'D - Hardcoded Anchor': true,
        'M - Hardcoded Anchor': true
    },
    gallery: {
        'D - Hardcoded Anchor': true,
        'M - Hardcoded Anchor': true
    }
}

// omit Context and Provider from names since it's implied from use
const CombinedAppProvider = combineWrappers(
    {
        Session: SessionProvider,
        ClientNavigation: ClientNavigationContextProvider,
        ReduxStore: ReduxStoreProvider,
        QueryCache: QueryCacheProvider,
        User: UserContextProvider,
        Settings: SettingsProvider,
        DidomiProvider
    },
    {
        DidomiProvider: didomiSettings
    }
)

const CombinedProfileWrapper = combineWrappers(
    {
        Theme: ThemeProvider,
        ProfileLayout
    },
    {
        ProfileLayout: { from: '/' }
    }
)

const CombinedPortalWrapper = combineWrappers({
    Luna: LunaContext.Provider, // we should refactor these 2 to MainThemeProvider
    Theme: ThemeProvider,
    Navigation: NavigationContext.Provider,
    AdManifest: AdManifestContext.Provider,
    AdCallbacks: AdCallbacksContext.Provider,
    FilledSlots: FilledSlotsContext.Provider,
    RefreshSlots: RefreshSlotsContext.Provider,
    ExternalAds: ExternalAdsProvider
})

function WebApp({ Component, router, pageProps }) {
    const isAmp = useAmp()

    // NOTE: pageProps?.pageProps is for fake-pages/entity pages (single entities) (custom) and
    // pageProps is for all other pages (default behavior). I have done this this way because I
    // needed to return other data for single entity pages in order to make them work.
    // See fake-pages/entity/index.js for reference on why this is neeeded.
    const { session, themeName, ads, gemiusId } = pageProps?.pageProps ?? pageProps ?? {}

    const pageTheme = Component.themeName ?? themeName ?? currentTheme
    // console.log({ parsedProps: pageProps?.pageProps ?? pageProps ?? {} })

    return (
        <CombinedAppProvider
            value={{
                Session: { session },
                ReduxStore: { store },
                User: { session },
                Settings: { clientName: xClientName }
            }}>
            <IsolatedAppHooks appName={AppName.ZENA} />

            {router?.pathname?.startsWith('/profil') ? (
                <CombinedProfileWrapper
                    value={{
                        Theme: { theme: themesObject.themes.profileDefault, pageTheme }
                    }}>
                    <Head>
                        <title>{process.env.NEXT_PUBLIC_APP_NAME_ZENA}</title>
                        {/* Use minimum-scale=1 to enable GPU rasterization */}
                        {!isAmp && (
                            <meta
                                key="viewport"
                                name="viewport"
                                content="minimum-scale=1,
                                    initial-scale=1,
                                    width=device-width,
                                    shrink-to-fit=no"
                            />
                        )}
                    </Head>
                    <GlobalStyles />
                    <Component {...pageProps} />
                </CombinedProfileWrapper>
            ) : (
                <CombinedPortalWrapper
                    value={{
                        Luna: { value: themesObject },
                        Theme: {
                            theme: themesObject.themes[
                                pageTheme || currentTheme || process.env.NEXT_PUBLIC_DEFAULT_THEME
                            ]
                        },
                        AdManifest: { value: ads }
                    }}>
                    <UserAgentHistoryTrap userAgent={UserAgent.FACEBOOK} />
                    <ReferrerHistoryTrap referrer={Referrer.GOOGLE} />
                    <Head>
                        <title>{process.env.NEXT_PUBLIC_APP_NAME_ZENA}</title>
                        <meta
                            key="google-site-verification"
                            name="google-site-verification"
                            content="WuDEXZqpsKaRSfMHK7lkdeeU986quAMSiA2U9zFri5k"
                        />
                        {/* Use minimum-scale=1 to enable GPU rasterization */}
                        {!isAmp && (
                            <meta
                                key="viewport"
                                name="viewport"
                                content="minimum-scale=1,
                                    initial-scale=1,
                                    width=device-width,
                                    shrink-to-fit=no"
                            />
                        )}
                    </Head>

                    <DefaultSeo {...SEO} />
                    <GlobalStyles />
                    <UpScoreContextProvider upScoreDomain="zena.net.hr" />
                    <Component {...pageProps} />

                    <AdsProvider
                        appName={pageVariants.ZENA}
                        dfpNetworkId={process.env.NEXT_PUBLIC_DFP_NETWORK_ID_ZENA}
                        collapseEmptyDivs
                        currentAdManifest={ads}
                        singleRequest
                        rubiconMagniteScriptName="dynamic/26588"
                        dependantZonesMap={dependantZonesMap}
                        ignoreZonesOnShallowNavigation={ignoreZonesOnShallowNavigation}
                    />
                </CombinedPortalWrapper>
            )}

            <TrackingScripts
                googleTagManagerId={process.env.NEXT_PUBLIC_GTM_ZENA}
                facebookPixelId={FB_PIXEL_ID}
                gemiusId={gemiusId ?? GEMIUS_IDENTIFIER}
                oneSignalAppId={process.env.NEXT_PUBLIC_ONESIGNAL_ID_ZENA}
                dotmetricsCategory="zena"
                exponeaToken={EXPONEA_TOKEN}
            />
        </CombinedAppProvider>
    )
}

WebApp.propTypes = {
    Component: PropTypes.func.isRequired,
    pageProps: PropTypes.shape({
        themeName: PropTypes.string,
        pageProps: PropTypes.shape({
            themeName: PropTypes.string
        })
    }),
    router: PropTypes.shape({
        route: PropTypes.string.isRequired,
        pathname: PropTypes.string
    }).isRequired
}

WebApp.defaultProps = {
    pageProps: undefined
}

export default WebApp
